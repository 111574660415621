import * as React from "react";
import { useState } from "react";
import Button from "./button";
import css from "./subject-form.module.css";

export const TESTS = {
  text: "^[^%]{2,}$",
  date: "([12]d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]d|3[01]))",
  email: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
  phone: "^+?[0-9]{3}-?[0-9]{6,12}$",
  textarea: "^(?!s*$).+",
  select: "^(?!s*$).+",
};

const defaultNewSubject = {
  label: "",
  email_to: "",
  email_cc: "",
  email_bcc: "",
  default_subject: 0,
};

const SubjectForm = ({
  mode,
  existingSubject,
  fields,
  setModal,
  add,
  update,
}) => {
  const [subject, setSubject] = useState(existingSubject || defaultNewSubject);

  const onSubmit = async () => {
    if (mode === "add") await add(subject);
    if (mode === "update") await update(subject);
  };

  return (
    <div className={css["subject-form"]}>
      {Object.keys(fields).map((key) => {
        const field = fields[key];
        const { type, label, value, required, checked } = field;

        const onChange = (e) => {
          const { value, checked } = e.target;
          const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
          setSubject({
            ...subject,
            [key]: newValue,
          });
        };

        if (type === "checkbox") {
          return (
            <label
              key={key}
              className={`${css["subject-form__label"]} ${css["subject-form__label--checkbox"]}`}
              htmlFor={key}
            >
              <input
                id={key}
                className={css["subject-form__input"]}
                type={type}
                name={key}
                defaultChecked={!!checked}
                required={required}
                onChange={onChange}
              />
              {label}
            </label>
          );
        }
        return (
          <label key={key} className={css["subject-form__label"]} htmlFor={key}>
            {label} :
            <input
              id={key}
              className={css["subject-form__input"]}
              type={type}
              name={key}
              defaultValue={value}
              required={required}
              pattern={TESTS[type]}
              onChange={onChange}
            />
          </label>
        );
      })}
      <div className={css["subject-form__buttons"]}>
        <Button
          className={`${css["subject-form__button"]} ${css["subject-form__button--cancel"]}`}
          onClick={() => setModal()}
        >
          Annuler
        </Button>
        <Button
          className={`${css["subject-form__button"]} ${css["subject-form__button--confirm"]}`}
          onClick={onSubmit}
        >
          Confirmer
        </Button>
      </div>
    </div>
  );
};

export default SubjectForm;
