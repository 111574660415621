import * as React from "react";
import Button from "./button";
import css from "./list.module.css";

const getCellValue = (key, cell) => {
  if (key === "news_date") return new Date(cell).toLocaleDateString("fr-FR");
  if (key !== "default_subject" && key !== "active") return cell;
  if (cell === 1) return "Oui";
  if (cell === 0) return "Non";
  return cell;
};

const List = ({ titles, rows, rowActions, rowLink }) => {
  return (
    <table className={css["list"]}>
      <thead>
        <tr>
          {titles.map((title, i) => (
            <th key={i} className={css["list__head-cell"]}>
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          const cells = Object.keys(row);
          return (
            <tr key={i} className={css["list__row"]}>
              {cells.map((key) => {
                const cell = row[key];
                const cellValue = getCellValue(key, cell);
                return (
                  <td key={key} className={css["list__cell"]}>
                    {cellValue}
                  </td>
                );
              })}
              {rowLink && (
                <td
                  className={`${css["list__cell"]} ${css["list__cell--link"]}`}
                >
                  <a
                    href={`https://www.peisey-nancroix.fr/actualites/${row[rowLink]}`}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    Lien vers l'actualité
                  </a>
                </td>
              )}
              {rowActions && (
                <td
                  className={`${css["list__cell"]} ${css["list__cell--buttons"]}`}
                >
                  {rowActions(row).map((action, i) => {
                    const { Icon, callback, tooltip } = action;
                    return (
                      <Button
                        key={i}
                        className={css["list__button"]}
                        onClick={callback}
                      >
                        <Icon
                          className={css["list__icon"]}
                          data-tooltip={tooltip}
                        />
                      </Button>
                    );
                  })}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default List;
