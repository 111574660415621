import * as React from "react";
import css from "./modal.module.css";
import Button from "./button";

const Modal = ({
  title,
  content,
  cancelCallback,
  confirmCallback,
  backdrop,
}) => {
  return (
    <div className={css["modal"]}>
      {(cancelCallback || backdrop) && (
        <div
          className={css["modal__backdrop"]}
          onClick={cancelCallback || backdrop}
        />
      )}
      <div className={css["modal__inner"]}>
        {title && <div className={css["modal__title"]}>{title}</div>}
        <div className={css["modal__content"]}>{content}</div>
        {(cancelCallback || confirmCallback) && (
          <div className={css["modal__buttons"]}>
            {cancelCallback && (
              <Button
                className={`${css["modal__button"]} ${css["modal__button--cancel"]}`}
                onClick={cancelCallback}
              >
                Annuler
              </Button>
            )}
            {confirmCallback && (
              <Button
                className={`${css["modal__button"]} ${css["modal__button--confirm"]}`}
                onClick={confirmCallback}
              >
                Valider
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
