import * as React from "react";
import { useRef, useEffect } from "react";
import classnames from "classnames";
import css from "./button.module.css";

const Button = ({
  tag = "button",
  type = "button",
  className,
  disabled = false,
  focus = false,
  onClick,
  children,
}) => {
  const buttonRef = useRef(null);
  const classes = classnames(css["button"], className);

  const clicked = (e) => {
    if (disabled) return;
    if (onClick) onClick(e);
  };
  useEffect(() => {
    if (focus && buttonRef?.current) buttonRef.current.focus();
  }, [focus, buttonRef]);

  const Tag = tag;

  return (
    <Tag
      type={type}
      className={classes}
      disabled={disabled}
      onClick={clicked}
      ref={buttonRef}
      tabIndex={0}
    >
      <span className={css["button__label"]}>{children}</span>
    </Tag>
  );
};

export default Button;
