import React from "react";
import { useLocalStorage } from "./useLocalStorage";
import { parse } from "../services/token";
import { checkAuth } from "../services/api";

const AuthContext = React.createContext();

function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function AuthProvider(props) {
  const [user, setUser] = useLocalStorage("user", undefined, true);
  const [token, setToken] = useLocalStorage("token", undefined, true);
  const [cmsToken, setCmsToken] = useLocalStorage("cms_token", undefined, true);

  const login = async (token) => {
    setUser(parse(token));
    const { token: newToken } = await checkAuth(token);
    setToken(newToken);
    setCmsToken(token);
  };

  const logout = () => {
    setToken(undefined);
    setUser(undefined);
    window.location.href = "https://www.peisey-nancroix.fr/";
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        token,
        cmsToken,
      }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth };
