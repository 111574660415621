import { get, post } from "./fetcher";
import { withConfiguration } from "./config";

export const checkAuth = async (cmsToken) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/auth/valid-cms-token`;
    return post(endpoint, { cmsToken });
  });
};

export const clearCache = async () => {
  const endpoint = "https://ublo.valraiso.net/api/clear-cache";
  return get(endpoint, { site: "mairiepeisey" });
};

export const startExport = async (token) => {
  const endpoint = "https://www.peisey-nancroix.fr/api/revalidate";
  return get(endpoint, { token, all: true });
};

export const getSubjects = async (token) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/subjects/get-list`;
    return get(endpoint, undefined, { Authorization: token });
  });
};

export const addSubject = async (
  token,
  label,
  email_to,
  email_cc,
  email_bcc,
  default_subject
) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/subjects/create`;
    return post(
      endpoint,
      { label, email_to, email_cc, email_bcc, default_subject },
      undefined,
      {
        Authorization: token,
      }
    );
  });
};

export const updateSubject = async (
  token,
  id,
  label,
  email_to,
  email_cc,
  email_bcc,
  default_subject
) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/subjects/update`;
    return post(
      endpoint,
      { id, label, email_to, email_cc, email_bcc, default_subject },
      undefined,
      {
        Authorization: token,
      }
    );
  });
};

export const removeSubject = async (token, id) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/subjects/remove`;
    return post(endpoint, { id }, undefined, { Authorization: token });
  });
};

export const getNews = async (token) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/news/get-list`;
    return get(endpoint, undefined, { Authorization: token });
  });
};

export const addNews = async (
  token,
  news_title,
  news_slug,
  news_date,
  active,
  cmsToken
) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/news/create`;
    return post(
      endpoint,
      { news_title, news_slug, news_date, active, cmsToken },
      undefined,
      {
        Authorization: token,
      }
    );
  });
};

export const updateNews = async (
  token,
  id,
  news_title,
  news_slug,
  news_date,
  active,
  cmsToken
) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/news/update`;
    return post(
      endpoint,
      { id, news_title, news_slug, news_date, active, cmsToken },
      undefined,
      {
        Authorization: token,
      }
    );
  });
};

export const removeNews = async (token, id, cmsToken) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/news/remove`;
    return post(endpoint, { id, cmsToken }, undefined, {
      Authorization: token,
    });
  });
};

export const getInscriptions = async (token) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/api/newsletter/get-inscription-list`;
    return get(endpoint, undefined, { Authorization: token });
  });
};
