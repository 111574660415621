import * as React from "react";
import classnames from "classnames";
import css from "./menu.module.css";
import Button from "./button";

const menus = [
  { code: "news", label: "Gestions des actualités" },
  { code: "contact", label: "Paramètres des contacts" },
  { code: "inscriptions", label: "Inscrits à L'écho de Peisey" },
];

const Menu = ({ currentMenu, setCurrentMenu }) => {
  const classes = (id) =>
    classnames(css["menu__item"], {
      [css["menu__item--current"]]: id === currentMenu,
    });

  return (
    <div className={css["menu"]}>
      {menus.map((menu, i) => {
        const { code, label } = menu;
        return (
          <Button
            key={code}
            className={classes(i)}
            onClick={() => setCurrentMenu(i)}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
};

export default Menu;
