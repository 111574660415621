import * as React from "react";
import { useState } from "react";
import css from "./admin.module.css";
import TopBar from "./top-bar";
import Menu from "./menu";
import News from "./news";
import Contact from "./contact";
import Inscriptions from "./inscriptions";

const Admin = () => {
  const [currentMenu, setCurrentMenu] = useState(0);
  return (
    <div className={css["admin"]}>
      <TopBar />
      <div className={css["admin__inner"]}>
        <Menu currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
        {currentMenu === 0 && <News />}
        {currentMenu === 1 && <Contact />}
        {currentMenu === 2 && <Inscriptions />}
      </div>
    </div>
  );
};

export default Admin;
