import * as React from "react";
import { useState, useEffect } from "react";
import List from "./list";
import Button from "./button";
import css from "./inscriptions.module.css";

import { useAuth } from "../hooks/useAuth";

import { getInscriptions } from "../services/api";

const Inscriptions = () => {
  const [inscriptions, setInscriptions] = useState([]);

  const { token } = useAuth();

  useEffect(() => {
    const get = async () => setInscriptions(await getInscriptions(token));
    get();
  }, [token]);

  const convertToCSV = () => {
    const items = [...inscriptions];
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");

    const link = document.createElement("a");
    link.setAttribute("id", "csv-dl-button");
    document.body.appendChild(link);
    const blob = new Blob([csv], { type: "text/csv" });
    var csvUrl = window.webkitURL.createObjectURL(blob);
    var filename = "liste-inscrits.csv";
    link.setAttribute("download", filename);
    link.setAttribute("href", csvUrl);
    link.click();
    document.body.removeChild(link);
  };

  const titles = ["N°", "Nom", "Adresse email"];

  return (
    <div className={css["inscriptions"]}>
      <Button className={css["inscriptions__dl-csv"]} onClick={convertToCSV}>
        Télécharger au format CSV
      </Button>
      <List titles={titles} rows={inscriptions} />
      <Button className={css["inscriptions__dl-csv"]} onClick={convertToCSV}>
        Télécharger au format CSV
      </Button>
    </div>
  );
};

export default Inscriptions;
