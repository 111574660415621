import * as React from "react";

const Icon = ({ width = 24, height = 24, ...props }) => (
  <svg
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    height={height}
    {...props}
  >
    {props.children}
  </svg>
);

export const BinIcon = (props) => (
  <Icon {...props}>
    <path d="M6 21h12V7H6v14zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z" />
  </Icon>
);

export const PenIcon = (props) => (
  <Icon {...props}>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM21.41 6.34l-3.75-3.75-2.53 2.54 3.75 3.75 2.53-2.54z" />
  </Icon>
);
