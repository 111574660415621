import * as React from "react";
import * as ReactDOM from "react-dom/client";
import TooltipProvider from "dt-design-system/es/tooltip";
import App from "./App";
import { AuthProvider } from "./hooks/useAuth";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <AuthProvider>
    <TooltipProvider>
      <App />
    </TooltipProvider>
  </AuthProvider>
);
