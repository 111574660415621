import * as React from "react";
import { useState } from "react";
import Button from "./button";
import css from "./news-form.module.css";
import { slugify } from "../services/utils";

export const TESTS = {
  text: "^[^%]{2,}$",
  date: "([12]d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]d|3[01]))",
  email: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
  phone: "^+?[0-9]{3}-?[0-9]{6,12}$",
  textarea: "^(?!s*$).+",
  select: "^(?!s*$).+",
};

const defaultNewNews = {
  news_title: "",
  news_slug: "",
  news_date: "",
  active: 0,
};

const NewsForm = ({ mode, existingNews, fields, setModal, add, update }) => {
  const [news, setNews] = useState(existingNews || defaultNewNews);

  const onSubmit = async () => {
    if (mode === "add") await add(news);
    if (mode === "update") await update(news);
  };

  return (
    <div className={css["news-form"]}>
      {Object.keys(fields).map((key) => {
        const field = fields[key];
        const { type, label, value, required, checked, readonly, placeholder } =
          field;

        const onChange = (e) => {
          const { value, checked } = e.target;
          const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
          if (e.target.name === "news_title") {
            const slug = slugify(newValue);
            setNews({
              ...news,
              [key]: newValue,
              news_slug: slug,
            });
            document.getElementById("news_slug").value = slug;
          } else {
            setNews({
              ...news,
              [key]: newValue,
            });
          }
        };

        if (type === "checkbox") {
          return (
            <label
              key={key}
              className={`${css["news-form__label"]} ${css["news-form__label--checkbox"]}`}
              htmlFor={key}
            >
              <input
                id={key}
                className={css["news-form__input"]}
                type={type}
                name={key}
                defaultChecked={!!checked}
                required={required}
                onChange={onChange}
              />
              {label}
            </label>
          );
        }
        return (
          <label key={key} className={css["news-form__label"]} htmlFor={key}>
            {label} :
            <input
              id={key}
              className={css["news-form__input"]}
              type={type}
              name={key}
              defaultValue={value}
              required={required}
              pattern={TESTS[type]}
              onChange={onChange}
              readOnly={readonly}
              placeholder={placeholder}
            />
          </label>
        );
      })}
      <div className={css["news-form__buttons"]}>
        <Button
          className={`${css["news-form__button"]} ${css["news-form__button--cancel"]}`}
          onClick={() => setModal()}
        >
          Annuler
        </Button>
        <Button
          className={`${css["news-form__button"]} ${css["news-form__button--confirm"]}`}
          onClick={onSubmit}
        >
          Confirmer
        </Button>
      </div>
    </div>
  );
};

export default NewsForm;
