import * as React from "react";
import { useState, useEffect } from "react";
import css from "./news.module.css";
import List from "./list";
import NewsForm from "./news-form";
import Button from "./button";
import Modal from "./modal";
import { PenIcon, BinIcon } from "./icons";

import { useAuth } from "../hooks/useAuth";

import {
  addNews,
  clearCache,
  getNews,
  removeNews,
  updateNews,
} from "../services/api";

const News = () => {
  const [newsList, setNewsList] = useState([]);
  const [modal, setModal] = useState();
  const { token, cmsToken } = useAuth();

  useEffect(() => {
    const get = async () => {
      const news = await getNews(token);
      const orderedNews = news.sort(
        (a, b) => new Date(b.news_date) - new Date(a.news_date)
      );
      setNewsList(orderedNews);
    };
    get();
  }, [token]);

  const add = async (news) => {
    const { news_title, news_slug, news_date, active } = news;
    const result = await addNews(
      token,
      news_title,
      news_slug,
      news_date,
      active,
      cmsToken
    );
    if (!result.error) {
      const { insertId } = result;
      const newNews = [...newsList, { id: insertId, ...news }];
      const orderedNews = newNews.sort(
        (a, b) => new Date(b.news_date) - new Date(a.news_date)
      );
      setNewsList(orderedNews);
      setModal(undefined);
      clearCache();
    }
  };

  const update = async (news) => {
    const { id, news_title, news_slug, news_date, active } = news;
    const result = await updateNews(
      token,
      id,
      news_title,
      news_slug,
      news_date,
      active,
      cmsToken
    );
    if (!result.error) {
      const newNews = [...newsList].map((sub) =>
        sub.id === id ? { ...sub, ...news } : sub
      );
      const orderedNews = newNews.sort(
        (a, b) => new Date(b.news_date) - new Date(a.news_date)
      );
      setNewsList(orderedNews);
      setModal(undefined);
      clearCache();
    }
  };

  const remove = async (id) => {
    const result = await removeNews(token, id);
    if (!result.error) {
      const newNews = [...newsList].filter((item) => item.id !== id);
      const orderedNews = newNews.sort(
        (a, b) => new Date(b.news_date) - new Date(a.news_date)
      );
      setNewsList(orderedNews);
      clearCache();
    }
  };

  const openModal = (mode, data) => () => {
    if (mode === "add") {
      setModal({
        title: "Ajouter une actualité",
        content: (
          <NewsForm
            mode={mode}
            fields={{
              news_title: {
                type: "text",
                label: "Titre",
                value: "",
                required: true,
              },
              news_slug: {
                type: "text",
                label: "Slug",
                value: "",
                required: true,
                readonly: true,
              },
              news_date: {
                type: "date",
                label: "Date de publication",
                value: "",
                required: true,
                placeholder: "JJ/MM/YYYY",
              },
              active: {
                type: "checkbox",
                label: "Actif",
                checked: false,
                required: true,
              },
            }}
            setModal={setModal}
            add={add}
          />
        ),
      });
    }
    if (mode === "update") {
      setModal({
        title: "Modifier une actualité",
        content: (
          <NewsForm
            mode={mode}
            existingNews={{
              id: data.id,
              news_title: data.news_title,
              news_slug: data.news_slug,
              news_date: data.news_date,
              active: data.active,
            }}
            fields={{
              news_title: {
                type: "text",
                label: "Titre",
                value: data.news_title,
                required: true,
              },
              news_slug: {
                type: "text",
                label: "Slug",
                value: data.news_slug,
                required: true,
                readonly: true,
              },
              news_date: {
                type: "date",
                label: "Date de publication",
                value: data.news_date,
                required: true,
                placeholder: "JJ/MM/YYYY",
              },
              active: {
                type: "checkbox",
                label: "Actif",
                checked: data.active,
                required: true,
              },
            }}
            setModal={setModal}
            update={update}
          />
        ),
      });
    }
    if (mode === "remove") {
      setModal({
        title: "Supprimer une actualité",
        content: "Etes-vous sur de vouloir supprimer cette actualité ?",
        cancelCallback: () => setModal(),
        confirmCallback: async () => {
          await remove(data.id);
          setModal(undefined);
        },
      });
    }
  };

  const titles = [
    "N°",
    "Titre",
    "Slug",
    "Date de publication",
    "Actif",
    "",
    "Actions",
  ];

  const actions = (data) => {
    const { id, news_title, news_slug, news_date, active } = data;
    return [
      {
        Icon: PenIcon,
        callback: openModal("update", {
          id,
          news_title,
          news_slug,
          news_date,
          active,
        }),
      },
      {
        Icon: BinIcon,
        callback: openModal("remove", { id }),
      },
    ];
  };

  return (
    <>
      <div className={css["news"]}>
        <div className={css["news__top"]}>
          Après création de l'actualité dans cette interface, rendez-vous sur
          cette dernière grâce au lien "<b>lien vers l'actualité</b>".
          <br />
          Connectez-vous en mode édition afin d'ajouter l'image de bandeau et le
          contenu de l'actualité.
          <br />
          Enfin, rendez-vous sur la page d'accueil en mode édition pour mettre
          la photo sur le bloc de l'actualité qui apparait dans la section
          "Actualités & Agenda".
        </div>
        <List
          titles={titles}
          rows={newsList}
          rowActions={actions}
          rowLink="news_slug"
        />
        <div className={css["news__buttons"]}>
          <Button className={css["news__add-news"]} onClick={openModal("add")}>
            Ajouter une actualité
          </Button>
        </div>
        <div className={css["news__tips"]}>
          <div className={css["news__tips-title"]}>Infos pratiques</div>
          <div className={css["news__tips-text"]}>
            <p>
              Afin de veiller au bon fonctionnement de ce module d'actualités,
              veuillez vérifier les points suivants avant d'exporter le site :
            </p>
            <ul>
              <li>
                Chaque titre d'actulité doit être <b>unique</b>.
              </li>
              <li>
                Seules les actualités ayant une{" "}
                <b>
                  date passée ou correspondant à la date du jour ET ayant la
                  case "actif" cochée seront visibles
                </b>{" "}
                sur le site.
              </li>
              <li>
                Pour créer un brouillon d'actualité, ne cochez pas la case
                "actif". Cette actualité sera alors{" "}
                <b>
                  accessible via le "Lien vers l'actualité" mais n'apparaitra
                  pas dans la liste des actualités
                </b>{" "}
                sur le site.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {modal && (
        <Modal
          title={modal.title}
          content={modal.content}
          cancelCallback={modal.cancelCallback}
          confirmCallback={modal.confirmCallback}
          backdrop={() => setModal(undefined)}
        />
      )}
    </>
  );
};

export default News;
