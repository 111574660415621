import * as React from "react";
import { useEffect } from "react";
import Admin from "./components/admin";
import Loader from "./components/loader";

import { useAuth } from "./hooks/useAuth";

import "./App.css";

const init = () => {
  const params = new URLSearchParams(document.location.search);
  return {
    cmsToken: params.get("token") || undefined,
  };
};

const App = () => {
  const { cmsToken } = init();
  const { token, login } = useAuth();

  useEffect(() => {
    if (cmsToken && !token) {
      login(cmsToken);
      window.history.replaceState("", document.title, "/");
    }
  }, [cmsToken, login, token]);

  return <div className="app">{token ? <Admin /> : <Loader />}</div>;
};

export default App;
