import * as React from "react";
import { useState, useEffect } from "react";
import css from "./contact.module.css";
import List from "./list";
import SubjectForm from "./subject-form";
import Button from "./button";
import Modal from "./modal";
import { PenIcon, BinIcon } from "./icons";

import { useAuth } from "../hooks/useAuth";

import {
  addSubject,
  getSubjects,
  removeSubject,
  updateSubject,
} from "../services/api";

const Contact = () => {
  const [subjects, setSubjects] = useState([]);
  const [modal, setModal] = useState();
  const { token } = useAuth();

  useEffect(() => {
    const get = async () => setSubjects(await getSubjects(token));
    get();
  }, [token]);

  const add = async (subject) => {
    const { label, email_to, email_cc, email_bcc, default_subject } = subject;
    const result = await addSubject(
      token,
      label,
      email_to,
      email_cc,
      email_bcc,
      default_subject
    );
    if (!result.error) {
      const { insertId } = result;
      const newSubjects = [...subjects, { id: insertId, ...subject }];
      setSubjects(newSubjects);
      setModal(undefined);
    }
  };

  const update = async (subject) => {
    const { id, label, email_to, email_cc, email_bcc, default_subject } =
      subject;
    const result = await updateSubject(
      token,
      id,
      label,
      email_to,
      email_cc,
      email_bcc,
      default_subject
    );
    if (!result.error) {
      const newSubjects = [...subjects].map((sub) =>
        sub.id === id ? { ...sub, ...subject } : sub
      );
      setSubjects(newSubjects);
      setModal(undefined);
    }
  };

  const remove = async (id) => {
    const result = await removeSubject(token, id);
    if (!result.error) {
      const newSubjects = [...subjects].filter((subject) => subject.id !== id);
      setSubjects(newSubjects);
    }
  };

  const openModal = (mode, data) => () => {
    if (mode === "add") {
      setModal({
        title: "Ajouter un sujet",
        content: (
          <SubjectForm
            mode={mode}
            fields={{
              label: {
                type: "text",
                label: "Sujet",
                value: "",
                required: true,
              },
              email_to: {
                type: "email",
                label: "Destinataire",
                value: "",
                required: true,
              },
              email_cc: {
                type: "email",
                label: "Copie",
                value: "",
              },
              email_bcc: {
                type: "email",
                label: "Copie cachée",
                value: "",
              },
              default_subject: {
                type: "checkbox",
                label: "Objet Mairie",
                checked: false,
              },
            }}
            setModal={setModal}
            add={add}
          />
        ),
      });
    }
    if (mode === "update") {
      setModal({
        title: "Modifier un sujet",
        content: (
          <SubjectForm
            mode={mode}
            existingSubject={{
              id: data.id,
              label: data.label,
              email_to: data.email_to,
              email_cc: data.email_cc,
              email_bcc: data.email_bcc,
              default_subject: data.default_subject,
            }}
            fields={{
              label: {
                type: "text",
                label: "Sujet",
                value: data.label,
                required: true,
              },
              email_to: {
                type: "email",
                label: "Destinataire",
                value: data.email_to,
                required: true,
              },
              email_cc: {
                type: "email",
                label: "Copie",
                value: data.email_cc,
              },
              email_bcc: {
                type: "email",
                label: "Copie cachée",
                value: data.email_bcc,
              },
              default_subject: {
                type: "checkbox",
                label: "Objet Mairie",
                checked: data.default_subject,
              },
            }}
            setModal={setModal}
            update={update}
          />
        ),
      });
    }
    if (mode === "remove") {
      setModal({
        title: "Supprimer un sujet",
        content: "Etes-vous sur de vouloir supprimer ce sujet ?",
        cancelCallback: () => setModal(),
        confirmCallback: async () => {
          await remove(data.id);
          setModal(undefined);
        },
      });
    }
  };

  const titles = [
    "N°",
    "Sujet",
    "Destinataire",
    "Copie",
    "Copie cachée",
    "Objet Mairie",
    "Actions",
  ];

  const actions = (data) => {
    const { id, label, email_to, email_cc, email_bcc, default_subject } = data;
    return [
      {
        Icon: PenIcon,
        callback: openModal("update", {
          id,
          label,
          email_to,
          email_cc,
          email_bcc,
          default_subject,
        }),
      },
      {
        Icon: BinIcon,
        callback: openModal("remove", { id }),
      },
    ];
  };

  return (
    <>
      <div className={css["contact"]}>
        <List titles={titles} rows={subjects} rowActions={actions} />
        <div className={css["contact__buttons"]}>
          <Button
            className={css["contact__add-subject"]}
            onClick={openModal("add")}
          >
            Ajouter un sujet
          </Button>
        </div>
        <div className={css["contact__tips"]}>
          <div className={css["contact__tips-title"]}>Infos pratiques</div>
          <div className={css["contact__tips-text"]}>
            <ul>
              <li>
                Chaque sujet doit être <b>unique</b>.
              </li>
              <li>
                Seules les actualités ayant{" "}
                <b>la case "Objet Mairie" cochée seront visibles</b> sur la page
                contact du site.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {modal && (
        <Modal
          title={modal.title}
          content={modal.content}
          cancelCallback={modal.cancelCallback}
          confirmCallback={modal.confirmCallback}
          backdrop={() => setModal(undefined)}
        />
      )}
    </>
  );
};

export default Contact;
