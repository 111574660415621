import * as React from "react";
import css from "./top-bar.module.css";
import Logo from "./logo";
import Button from "./button";

import { useAuth } from "../hooks/useAuth";

const TopBar = () => {
  const { logout } = useAuth();

  return (
    <div className={css["top-bar"]}>
      <div className={css["top-bar__inner"]}>
        <div className={css["top-bar__logo"]}>
          <Logo />
        </div>
        <Button onClick={logout}>Déconnexion</Button>
      </div>
    </div>
  );
};

export default TopBar;
